import '../../static/css/reset.css'
import '../../static/css/wrapper.css'
import '../../static/css/header.css'
import '../../static/css/company.css'
import '../../static/css/breadcrumb.css'
import '../../static/css/footer.css'

import React from 'react'
import { Header } from '../components/Header'
import { Breadcrumb } from '../components/Breadcrumb'
import { Footer } from '../components/Footer'
import Seo from '../components/Seo'
import { url } from '../config/domain'

export default function Company({ location }: any): JSX.Element {
  const locatePath = location.pathname.split('/')

  return (
    <div>
      <div className="wrapper">
        <Seo
          title={'株式会社BlackLight - 会社概要'}
          description={
            '株式会社BlackLightの会社概要を掲載しています。KURORO BLOGは、「モノづくりから始まるエンジニア」をコンセプトに、プログラミングに関心を持ってもらうための情報共有サイトです。'
          }
          url={url + 'company/'}
          noIndex={false}
          contentType="article"
        />

        <Header location={locatePath} />

        <h1 className="company--h1">会社概要</h1>

        <div className="company">
          <img
            src="https://firebasestorage.googleapis.com/v0/b/kuro-site.appspot.com/o/images%2Fheader_logo.png?alt=media&token=08679d84-12bf-4d77-aeb4-bac7f3f40f8d"
            width={300}
            height={300}
            alt="KURORO BLOGのロゴ画像"
          />

          <div className="companyTable">
            <dl className="companyTable__item">
              <dt className="companyTable__title">会社名</dt>
              <dd className="companyTable__content">
                <div className="companyTable__contentInner">
                  <p>株式会社BlackLight</p>
                </div>
              </dd>
            </dl>

            <dl className="companyTable__item">
              <dt className="companyTable__title">本社所在地</dt>
              <dd className="companyTable__content">
                <div className="companyTable__contentInner">
                  <p>
                    〒105-0014
                    <br />
                    東京都港区芝1丁目10-12トーシンフェニックス芝アストラーレ1201号(
                    <a
                      href="https://www.google.com/maps/search/?api=1&query=東京都港区芝1丁目10-12トーシンフェニックス芝アストラーレ1201号"
                      rel="noopener noreferrer"
                      target="_blank"
                    >
                      アクセスマップ
                    </a>
                    )
                  </p>
                </div>
              </dd>
            </dl>

            <dl className="companyTable__item">
              <dt className="companyTable__title">法人番号</dt>
              <dd className="companyTable__content">
                <div className="companyTable__contentInner">
                  <p>
                    <a
                      href="https://www.houjin-bangou.nta.go.jp/henkorireki-johoto.html?selHouzinNo=2010401165604"
                      rel="noopener noreferrer"
                      target="_blank"
                    >
                      2010401165604
                    </a>
                  </p>
                </div>
              </dd>
            </dl>

            <dl className="companyTable__item">
              <dt className="companyTable__title">代表者</dt>
              <dd className="companyTable__content">
                <div className="companyTable__contentInner">
                  <p>代表取締役 黒光 俊秀</p>
                </div>
              </dd>
            </dl>

            <dl className="companyTable__item">
              <dt className="companyTable__title">設立日</dt>
              <dd className="companyTable__content">
                <div className="companyTable__contentInner">
                  <time dateTime="2022-02-15">2022年2月15日</time>
                </div>
              </dd>
            </dl>

            <dl className="companyTable__item">
              <dt className="companyTable__title">資本金</dt>
              <dd className="companyTable__content">
                <div className="companyTable__contentInner">
                  <p>500,000円（2022年2月15日現在）</p>
                </div>
              </dd>
            </dl>

            <dl className="companyTable__item">
              <dt className="companyTable__title">事業内容</dt>
              <dd className="companyTable__content">
                <div className="companyTable__contentInner">
                  <p>WEBコンサルティング業, WEBシステム・アプリ受託開発業</p>
                </div>
              </dd>
            </dl>
          </div>

          <p className="company--contact">
            お問い合わせは
            <strong>
              <a href="/contacts/">こちら</a>
            </strong>
            からお願いいたします。
          </p>
        </div>

        <Breadcrumb
          breadcrumb={[
            {
              link: '/',
              text: 'KURORO BLOG',
            },
            {
              link: '/company/',
              text: '会社概要',
            },
          ]}
        />

        <Footer />
      </div>
    </div>
  )
}
